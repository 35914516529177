/* eslint-disable camelcase */
import React, { FC, useEffect } from 'react';
import { graphql } from 'gatsby';
import {
  PageLayout,
  PageHeading,
  TwoColumnSection,
  Statistics,
  OurTeam,
  RenderVideoAndImage,
  FAQ,
} from 'components';
import { AboutUsPageType } from 'types';

const About: FC<AboutUsPageType> = ({ data }) => {
  const {
    title,
    tagline,
    pageTheme,
    description,
    seo,
    aboutUsStats: { stat },
    videoImage,
    titleAndText: { title: twoColTextHeading, content },
    informationSection,
    faq: { title: faqTitle, tagline: faqTagline, faqs },
    banner,
  } = data.AboutUs;
  const VideoGallery: any = [];

  useEffect(() => {
    VideoGallery.push(videoImage.video?.localFile.publicURL);
  }, [VideoGallery]);
  return (
    <PageLayout
      title={title}
      metaTitle={seo?.metaTitle ?? title}
      description={seo?.metaDescription}
      image={seo?.shareImage?.localFile?.publicURL}
      type="About Us"
      className={`${pageTheme}`}
      delaySec={0.3}
      bannerTitle={banner?.title}
      bannerTagline={banner?.tagline}
      bannerImage={banner?.image}
      bannerButton={banner?.ctaButton}
      bannerPlaceholder={banner?.postcodePlaceholder}
      faqs={faqs}
    >
      <div className="container">
        <PageHeading
          title={title}
          subtitle={tagline}
          description={description}
          level={1}
        />
        <Statistics textColor="#9016f4" stats={stat} />
      </div>
      <RenderVideoAndImage
        data={videoImage}
        altText={title}
        hasPlayButton
        hasBorder
        buttontype="text"
        buttonsize="large"
        videogallery={VideoGallery}
        showControls
        showModal
        showModalforMobile
      />
      <div className="container">
        <div className="section-wrapper">
          <TwoColumnSection title={twoColTextHeading} content={content} />
        </div>
        <OurTeam TeamList={informationSection} />
        <section className="home-page-faq-section">
          <PageHeading title={faqTitle} subtitle={faqTagline} />
          <FAQ Faqs={faqs} />
        </section>
      </div>
    </PageLayout>
  );
};

export default About;
export const query = graphql`
  query AboutUsPageQuery {
    AboutUs: strapiAboutUsPage {
      title
      tagline
      pageTheme
      description: pageDescription
      seo {
        metaDescription
        metaTitle
        shareImage {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 400, height: 400)
            }
          }
        }
      }
      banner {
        ctaButton
        id
        postcodePlaceholder
        tagline
        title
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      aboutUsStats {
        stat {
          id
          title
          value
        }
      }
      videoImage {
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
        }
        video {
          localFile {
            publicURL
          }
        }
      }
      titleAndText {
        title
        content
      }
      faq {
        tagline
        title
        faqs {
          answer
          title
        }
      }
      informationSection {
        id
        title
        content
        videoImage {
          image {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
              publicURL
            }
          }
        }
      }
    }
  }
`;

// TODO add following back into videoImage
// video {
//   localFile {
//     url
//   }
// }
